import React from "react";
import EntryContent from "../EntryContent";
import { PostHeader } from "./PostHeader";
import { PostFooter } from "./PostFooter";

const PostFull = ({
  content,
  categories = [],
  tags,
  title,
  dateTime,
  dateFormatted
}) => (
  <article
    className="post type-post format-standard hentry"
    itemScope=""
    itemProp="blogPost"
    itemType="http://schema.org/BlogPosting"
  >
    <PostHeader
      className="hentry__header"
      title={title}
      dateTime={dateTime}
      dateFormatted={dateFormatted}
    />

    <EntryContent itemProp="articleBody">
      <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
    </EntryContent>
    <PostFooter
      categories={categories}
      className="hentry__footer"
      tags={tags}
    />
  </article>
);

export { PostFull };
