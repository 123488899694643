import React from "react";
import { graphql, StaticQuery } from "gatsby";

function getName(list, id, defaultLabel = "") {
  const target = list.edges.find(({ node }) => node.id === String(id));

  return target?.node.name || defaultLabel || id;
}

const PostFooterRaw = ({
  categories,
  className = "",
  tags,
  allCategories,
  allTags
}) => (
  <footer className={className}>
    Filed under:{" "}
    {categories.map((category) => (
      <span key={category}>
        {getName(allCategories, category, "Uncategorised")}
      </span>
    ))}
    {tags && tags.length ? (
      <p className="hentry__footer__tags">
        <span className="tags-title">Tags: </span>
        {tags.map((tag) => (
          <span key={`${tag}tag`}>{getName(allTags, tag)} </span>
        ))}
      </p>
    ) : null}
  </footer>
);

const PostFooter = ({ categories = [], className, tags = [] }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressTags {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        allWordpressCategories {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `}
    render={(data) => (
      <PostFooterRaw
        categories={categories}
        className={className}
        tags={tags}
        allCategories={data.allWordpressCategories}
        allTags={data.allWordpressTags}
      />
    )}
  />
);

export { PostFooter };
