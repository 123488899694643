import React from "react";
import { AUTHOR } from "../../config";
import "./Byline.scss";

const Byline = ({ dateTime, dateFormatted }) => (
  <p className="byline entry-meta vcard">
    Posted{" "}
    <time
      className="updated entry-time"
      dateTime={dateTime}
      itemProp="datePublished"
    >
      {dateFormatted}
    </time>{" "}
    <span className="by">by</span>{" "}
    <span
      className="entry-author author"
      itemProp="author"
      itemScope=""
      itemType="http://schema.org/Person"
    >
      {AUTHOR}
    </span>
  </p>
);

export { Byline };
