import React from "react";
import Byline from "../Byline";
import Title from "../Title";

const PostHeader = ({ className = "", title, dateTime, dateFormatted }) => (
  <header className={className}>
    <h1 className="entry-title" itemProp="headline" rel="bookmark">
      <Title title={title} />
    </h1>

    <Byline dateTime={dateTime} dateFormatted={dateFormatted} />
  </header>
);

export { PostHeader };
