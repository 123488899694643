import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { SITE_TITLE } from "../config";
import Layout from "../components/Layout";
import PostFull from "../components/PostFull";

const BlogPost = ({ data }) => {
  const { wordpressPost: post } = data;

  return (
    <Layout>
      <Helmet title={`${post.title.rendered} - ${SITE_TITLE}`} />
      <PostFull
        content={post.content}
        categories={post.categories}
        tags={post.tags}
        title={post.title}
        dateTime={post.dateTime}
        dateFormatted={post.dateFormatted}
        author={post.author}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  fragment PostFields on WordpressPost {
    slug
    content {
      rendered
    }
    date(formatString: "MMMM DD, YYYY")
    title {
      rendered
    }
  }
  query BlogPostByID($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title {
        rendered
      }
      slug
      content {
        rendered
      }
      dateTime: date
      dateFormatted: date(formatString: "Do MMMM YYYY")
      categories
      tags
      author
    }
  }
`;
